export const DEFAULT_POLYGON_PATH = 'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)'
export const PATH_1 = 'polygon(2% 27%, 23% 1%, 31% 4%, 91% 1%, 64% 3%, 15% 34%, 74% 55%, 13% 64%, 5% 48%, 90% 72%, 30% 99%, 95% 64%, 86% 19%, 88% 65%, 17% 29%, 48% 0%)'
export const PATH_2 = 'polygon(92% 42%, 71% 31%, 73% 91%, 97% 16%, 94% 52%, 44% 20%, 7% 15%, 97% 46%, 82% 86%, 2% 47%, 42% 62%, 33% 85%, 5% 57%, 33% 91%, 88% 50%, 93% 0%)'
export const PATH_3 = 'polygon(1% 47%, 95% 96%, 12% 48%, 52% 0%, 19% 78%, 28% 33%, 75% 3%, 1% 68%, 68% 36%, 34% 41%, 12% 24%, 10% 88%, 12% 13%, 44% 23%, 7% 6%, 35% 54%)'
export const PATH_4 = 'polygon(29% 49%, 47% 95%, 34% 31%, 3% 36%, 46% 0%, 5% 30%, 43% 29%, 7% 30%, 38% 98%, 36% 3%, 36% 60%, 1% 15%, 48% 46%, 20% 87%, 64% 44%, 23% 77%)'
export const PATH_5 = 'polygon(8% 95%, 62% 56%, 80% 78%, 76% 67%, 92% 55%, 64% 60%, 67% 50%, 83% 52%, 36% 83%, 79% 9%, 54% 32%, 41% 54%, 8% 82%, 28% 71%, 3% 64%, 6% 54%)'
export const PATH_6 = 'polygon(20% 62%, 42% 88%, 53% 61%, 41% 4%, 3% 88%, 51% 85%, 31% 27%, 64% 15%, 39% 82%, 50% 94%, 81% 82%, 49% 40%, 49% 25%, 12% 67%, 48% 68%, 56% 29%)'

export default {
	DEFAULT_POLYGON_PATH,
	PATH_1,
	PATH_2,
	PATH_3,
	PATH_4,
	PATH_5,
	PATH_6
}
