<template>
	<div class="relative h-auto min-h-screen overflow-hidden bg-gray-100 isolate">
		<AlertBanner
			v-if="!online"
			:alert="offlineAlert"
			class="fixed top-0 z-50 w-full"
		/>
		<div :class="[{'mt-16': !online}, 'container relative max-w-3xl mx-auto text-2xl text-center']">
			<Head>
				<Meta name="viewport" content="initial-scale=1.0, user-scalable=no" />
				<Link rel="manifest" href="/kiosk.webManifest" />
			</Head>
			<TheLogo class="pt-12 mx-auto mb-10 lg:pt-24 w-96" />
			<slot />
		</div>

		<GenerativeAbstractBackground
			position-classes="-top-80 left-0 xl:justify-start"
			gradient-classes="bg-gradient-to-bl from-mx-maroon/70 via-mx-orange-muted to-mx-red"
			size-classes="aspect-[400/600] w-[60rem] origin-top-left"
			rotate-class="rotate-[45deg]"
			blur-class="blur-2xl"
			:polygon-path="PATH_2"
		/>
		<GenerativeAbstractBackground
			position-classes="-top-80 right-0 xl:justify-start"
			gradient-classes="bg-gradient-to-bl from-mx-maroon/70 via-mx-orange-muted to-mx-red"
			size-classes="aspect-[400/600] 2xl:w-[120rem] w-[90rem] origin-bottom"
			blur-class="blur-2xl"
			:polygon-path="PATH_2"
		/>
	</div>
</template>

<script setup>
import { PATH_2 } from '@/constants/generative'
import { useOnline } from '@vueuse/core'

const online = useOnline()

const offlineAlert = {
	title: 'You Are Offline',
	message: 'Please check your connection and try again.'
}

</script>
