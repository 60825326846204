<template>
	<div class="w-full">
		<ClientOnly>
			<div
				v-if="showControls"
				class="absolute inset-x-0 top-2"
			>
				<slot
					name="default"
					:generate-polygon="generatePolygon"
				>
					<div class="flex justify-center gap-2">
						<button
							class="px-2 py-1 rounded hover:bg-mx-gray-300 dark:hover:bg-mx-green-700"
							@click="refreshPolygon"
						>
							<ArrowPathIcon class="w-6 h-6 text-mx-green-800 dark:text-mx-gray-300" />
						</button>
						<button
							:class="[
								'px-2 py-1 rounded hover:bg-mx-gray-300 dark:hover:bg-mx-green-700',
								{ 'opacity-50' : indexAtBeginning }
							]"
							:disabled="indexAtBeginning"
							@click="goToPreviousPolygon"
						>
							<BackwardIcon class="w-6 h-6 text-mx-green-800 dark:text-mx-gray-300" />
						</button>
						<button
							:class="[
								'px-2 py-1 rounded hover:bg-mx-gray-300 dark:hover:bg-mx-green-700',
								{ 'opacity-50' : indexAtEnd }
							]"
							:disabled="indexAtEnd"
							@click="goToNextPolygon"
						>
							<ForwardIcon class="w-6 h-6 text-mx-green-800 dark:text-mx-gray-300" />
						</button>
						<button
							class="px-2 py-1 rounded hover:bg-mx-gray-300 dark:hover:bg-mx-green-700"
							@click="pauseGeneration"
						>
							<PauseIcon class="w-6 h-6 rounded text-mx-green-800 dark:text-mx-gray-300 hover:bg-mx-gray-300 dark:hover:bg-mx-green-700" />
						</button>
						<button
							class="px-2 py-1 rounded hover:bg-mx-gray-300 dark:hover:bg-mx-green-700"
							@click="startGeneration"
						>
							<PlayIcon class="w-6 h-6 rounded text-mx-green-800 dark:text-mx-gray-300 hover:bg-mx-gray-300 dark:hover:bg-mx-green-700" />
						</button>
						<button
							class="px-2 py-1 rounded hover:bg-mx-gray-300 dark:hover:bg-mx-green-700"
							@click="copyToClipboard"
						>
							<ClipboardIcon class="w-6 h-6 rounded text-mx-green-800 dark:text-mx-gray-300 hover:bg-mx-gray-300 dark:hover:bg-mx-green-700" />
						</button>
					</div>
				</slot>
			</div>

			<div
				v-show="clipPath"
				ref="polygonElement"
				:class="[
					'absolute inset-x-0 flex overflow-hidden -z-10 transform-gpu',
					positionClasses,
					opacityClasses,
					blurClass
				]"
				aria-hidden="true"
			>
				<div
					:class="[
						'flex-none transform transition-all duration-500 ease-in-out',
						sizeClasses,
						rotateClass,
						marginClasses,
						gradientClasses
					]"
					:style="{ clipPath: clipPath }"
				/>
			</div>
		</ClientOnly>
	</div>
</template>

<script setup lang="ts">
// NOTE: add relative, overflow-hidden and isolate to parent element
import { DEFAULT_POLYGON_PATH } from '@/constants/generative'
import { ArrowPathIcon, PauseIcon, PlayIcon, BackwardIcon, ForwardIcon, ClipboardIcon } from '@heroicons/vue/24/outline'
import { useToast } from '@/composables/useToast'

const { addToast } = useToast()

const props = defineProps({
	generate: {
		type: Boolean,
		default: false
	},
	generateOnce: {
		type: Boolean,
		default: false
	},
	showControls: {
		type: Boolean,
		default: false
	},
	polygonPath: {
		type: String,
		default: DEFAULT_POLYGON_PATH
	},
	positionClasses: {
		type: String,
		default: '-top-80 left-0 xl:justify-start'
	},
	opacityClasses: {
		type: String,
		default: 'opacity-20'
	},
	sizeClasses: {
		type: String,
		default: 'aspect-[1212/800] w-[80rem] origin-top-left'
	},
	rotateClass: {
		type: String,
		default: 'rotate-[25deg]'
	},
	marginClasses: {
		type: String,
		default: 'xl:ml-0 xl:mr-[calc(50%-10rem)] ml-[-20rem]'
	},
	gradientClasses: {
		type: String,
		default: 'bg-gradient-to-bl from-mx-maroon/70 to-mx-orange-muted'
	},
	blurClass: {
		type: String,
		default: 'blur-2xl'
	},
	interval: {
		type: Number,
		default: 2000
	}
})

const { generate, generateOnce, showControls, polygonPath, interval } = toRefs(props)

const polygonElement = ref<HTMLElement | null>(null)
const generatedPolygonPath = ref('')
const currentIndex = ref(0)
const intervalId = ref<NodeJS.Timeout | null>(null)
const polygonMap = ref(new Map())
const indexAtBeginning = computed(() => currentIndex.value === 0)
const indexAtEnd = computed(() => currentIndex.value === polygonMap.value.size - 1)

const copyToClipboard = () => {
	if (polygonElement.value) {
		if (clipPath.value) {
			navigator.clipboard.writeText(clipPath.value)
				.then(() => {
					addToast({
						title: 'Copied to clipboard',
						message: 'The polygon path has been copied to your clipboard',
						notificationType: 'success'
					})
				})
				.catch((_err) => { // TODO: Add error handling
					// console.error('Error in copying text: ', err)
				})
		}
	}
}

const randomizePolygon = () => {
	let string = ''
	const points = []
	for (let i = 0; i < 16; i++) {
		const x = Math.floor(Math.random() * 100)
		const y = Math.floor(Math.random() * 100)
		points.push(`${x}% ${y}%`)
	}
	string = `polygon(${points.join(', ')})`
	if (polygonMap.value.size) {
		currentIndex.value = currentIndex.value + 1
	}
	polygonMap.value.set(`polygon ${currentIndex.value}`, string)
	return string
}

const goToNextPolygon = () => {
	if (currentIndex.value === polygonMap.value.size - 1) {
		currentIndex.value = 0
	} else {
		currentIndex.value++
	}
	generatedPolygonPath.value = polygonMap.value.get(`polygon ${currentIndex.value}`) || ''
}

const goToPreviousPolygon = () => {
	pauseGeneration()
	if (currentIndex.value === 0) {
		currentIndex.value = polygonMap.value.size - 1
	} else {
		currentIndex.value--
	}
	generatedPolygonPath.value = polygonMap.value.get(`polygon ${currentIndex.value}`) || ''
}

const clipPath = computed(() => {
	if (showControls.value) {
		return generatedPolygonPath.value
	}
	return (generate.value || generateOnce.value) ? generatedPolygonPath.value : polygonPath.value
})

watch(clipPath, (value) => {
	polygonMap.value.set(`polygon ${currentIndex.value}`, value)
})

const refreshPolygon = () => {
	currentIndex.value = polygonMap.value.size - 1
	pauseGeneration()
	generatePolygon()
}

const generatePolygon = () => {
	generatedPolygonPath.value = randomizePolygon()
}

const pauseGeneration = () => {
	if (intervalId.value) {
		clearInterval(intervalId.value)
		intervalId.value = null
	}
}

const startGeneration = () => {
	if (currentIndex.value) {
		currentIndex.value = polygonMap.value.size - 1
		generatePolygon()
	}

	intervalId.value = setInterval(() => {
		generatePolygon()
	}, interval.value)
}

onMounted(() => {
	if (generateOnce.value || showControls.value) {
		generatedPolygonPath.value = randomizePolygon()
		return
	}
	if (generate.value) {
		generatedPolygonPath.value = randomizePolygon()
		intervalId.value = setInterval(() => {
			generatedPolygonPath.value = randomizePolygon()
		}, interval.value)
	}
})

onUnmounted(() => {
	if (intervalId.value) { clearInterval(intervalId.value) }
})
</script>
